import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UG_Criteria001 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();
  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  //post method for criteria_01
  const [MainYear1, setMainYear1] = useState("");

  const [TeacherPar, setTeacherPar] = useState("");
  const [BodyName, setBodyName] = useState("");
  const [Desc1, setDesc1] = useState("");
  const [Link1, setLink1] = useState("");

  const [PrgmCode, setPrgmCode] = useState("");
  const [YOfIntro, setYOfIntro] = useState("");
  const [PrgName1, setPrgName1] = useState("");
  const [Status, setStatus] = useState("");
  const [YOfCBCS, setYOfCBCS] = useState("");
  const [YOfRevision, setYOfRevision] = useState("");
  const [Revision, setRevision] = useState("");
  const [Link2, setLink2] = useState("");
  const [Desc2, setDesc2] = useState("");

  const [CertiName, setCertiName] = useState("");
  const [CourseCode, setCourseCode] = useState("");
  const [YOfOffering, setYOfOffering] = useState("");
  const [NoOfTimes, setNoOfTimes] = useState("");
  const [CourseDuration, setCourseDuration] = useState("");
  const [NoOfStudents, setNoOfStudents] = useState("");
  const [CourseCompletion, setCourseCompletion] = useState("");
  const [Link3, setLink3] = useState("");
  const [Desc3, setDesc3] = useState("");

  const [PPrgmCode, setPPrgmCode] = useState("");
  const [PrgName2, setPrgName2] = useState("");
  const [YYOfOffering, setYYOfOffering] = useState("");
  const [StdCourseStudied, setStdCourseStudied] = useState("");
  const [ExpCourse, setExpCourse] = useState("");
  const [Link4, setLink4] = useState("");
  const [Desc4, setDesc4] = useState("");

  const [PPPrgmCode, setPPPrgmCode] = useState("");
  const [PrgName3, setPrgName3] = useState("");
  const [StdList, setStdList] = useState("");
  const [Link5, setLink5] = useState("");
  const [Desc5, setDesc5] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getugcriteria1 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getugcriteria1();
  }, []);

  const ugcriteria1 = async () => {
    console.log("helo 112: ", {
      Year: MainYear1,
      Criteria01_113: obj1,
      Link1: Link1,
      Criteria01_121: obj2,
      Link2: Link2,
      Criteria01_122: obj3,
      Link3: Link3,
      Criteria01_132: obj4,
      Link4: Link4,
      Criteria01_133: obj5,
      Link5: Link5,
      addedByFac: facultyDetails?._id,
    });
    if (!MainYear1) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/adddetails01",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Year: MainYear1,
          Criteria01_113: obj1,
          Link1: Link1,
          Criteria01_121: obj2,
          Link2: Link2,
          Criteria01_122: obj3,
          Link3: Link3,
          Criteria01_132: obj4,
          Link4: Link4,
          Criteria01_133: obj5,
          Link5: Link5,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          navigate("/ugcriteria01");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (!TeacherPar || !BodyName || !Link1 || !Desc1) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Link1);
      let obj = {
        criteria: "1.1.3",
        TeacherPar: TeacherPar,
        BodyName: BodyName,
        Link1: Link1,
        Desc1: Desc1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      setedit1(false);
      getugcriteria1();
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add2) {
        if (
          !PrgName1 ||
          !PrgmCode ||
          !Status ||
          !YOfIntro ||
          !YOfCBCS ||
          !YOfRevision ||
          !Link2 ||
          !Desc2
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Link2);
      let obj = {
        criteria: "1.2.1",
        PrgName1: PrgName1,
        PrgmCode: PrgmCode,
        Status: Status,
        YOfIntro: YOfIntro,
        YOfCBCS: YOfCBCS,
        YOfRevision: YOfRevision,
        Revision: Revision,
        Link2: Link2,
        Desc2: Desc2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getugcriteria1();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !CertiName ||
          !CourseCode ||
          !YOfOffering ||
          !NoOfTimes ||
          !CourseDuration ||
          !NoOfStudents ||
          !CourseCompletion ||
          !Link3 ||
          !Desc3
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Link3);
      let obj = {
        criteria: "1.2.2 & 1.2.3",
        CertiName: CertiName,
        CourseCode: CourseCode,
        YOfOffering: YOfOffering,
        NoOfTimes: NoOfTimes,
        CourseDuration: CourseDuration,
        NoOfStudents: NoOfStudents,
        CourseCompletion: CourseCompletion,
        Link3: Link3,
        Desc3: Desc3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getugcriteria1();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !PrgName2 ||
          !PPrgmCode ||
          !YYOfOffering ||
          !StdCourseStudied ||
          !ExpCourse ||
          !Desc4 ||
          !Link4
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Link4);
      let obj = {
        criteria: "1.3.2",
        PrgName2: PrgName2,
        PPrgmCode: PPrgmCode,
        YYOfOffering: YYOfOffering,
        StdCourseStudied: StdCourseStudied,
        ExpCourse: ExpCourse,
        Desc4: Desc4,
        Link4: Link4,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getugcriteria1();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (!PrgName3 || !PPPrgmCode || !StdList || !Link5 || !Desc5) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", Link5);
      let obj = {
        criteria: "1.3.3",
        PrgName3: PrgName3,
        PPPrgmCode: PPPrgmCode,
        StdList: StdList,
        Link5: Link5,
        Desc5: Desc5,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getugcriteria1();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="add-gr">
        <div className="container">
          <div className="pt-4   " style={{ padding: "10px" }}>
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              UnderGraduate_Criteria-01
            </span>
          </div>

          <div className="container mt-3">
            <div className="row-fluid">
              <div
                class=" col-md-6-fluid border"
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <label>Select Year</label>
                {obj1?.MainYear1 ? (
                  <p>{obj1?.MainYear1}</p>
                ) : (
                  <select
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setMainYear1(e.target.value)}
                  >
                    <option>Select Year</option>
                    {allYears
                      ?.sort((a, b) => {
                        return Number(a?.from) - Number(b?.from);
                      })
                      ?.map((item) => {
                        return (
                          <option value={`${item?.from}-${item?.to}`}>
                            {item?.from} - {item?.to}
                          </option>
                        );
                      })}{" "}
                  </select>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 1.1.3</h3>

              <div className="row">
                <div className="col-lg-5  mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher participated
                  </label>
                  {obj1?.TeacherPar ? (
                    <p>{obj1?.TeacherPar}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTeacherPar(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-7   mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the body in which full time teacher participated
                  </label>
                  {obj1?.BodyName ? (
                    <p>{obj1?.BodyName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setBodyName(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-12  mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj1?.Desc1 ? (
                    <p>{obj1?.Desc1}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      rows={3}
                      onChange={(e) => {
                        setDesc1(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Upload the relevant document
                  </label>

                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setLink1(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="pt-2 text-end mt-3 ">
                  {add1 ? (
                    <button
                      type="submit"
                      className="btn  btn-success  m-1   "
                      onClick={() => {
                        cat1();
                      }}
                    >
                      ADD
                    </button>
                  ) : (
                    <>
                      {" "}
                      <button
                        className="btn btn-warning "
                        onClick={handleShow}
                        style={{
                          fontSize: "16px",
                          color: "",
                          fontWeight: "600",
                        }}
                      >
                        Preview
                      </button>
                    </>
                  )}
                </div>
              </div>

              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <span>Criteria-01-121</span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the teacher participated
                      </label>

                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={TeacherPar}
                            onChange={(e) => {
                              setTeacherPar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{TeacherPar}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the body in which full time teacher participated
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={BodyName}
                            onChange={(e) => setBodyName(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{BodyName}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>

                      {edit1 ? (
                        <>
                          <textarea
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            value={Desc1}
                            onChange={(e) => setDesc1(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="upload1">
                        {" "}
                        Upload the relevant document
                      </label>

                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="Year"
                            id="upload1"
                            accept="image/*"
                            onChange={(e) => setLink1(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link1?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                  <button
                    className=" btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat1();
                      handleClose();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 1.2.1</h3>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>
                  {obj2?.PrgmCode ? (
                    <p>{obj2?.PrgmCode}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setPrgmCode(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  {obj2?.PrgName1 ? (
                    <p>{obj2?.PrgName1}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setPrgName1(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="BCA">BCA</option>
                      <option value="BSC">BSC</option>
                      <option value="BBA">BBA</option>
                      <option value="B.Com">B.Com</option>
                    </select>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year Of Introduction
                  </label>
                  {obj2?.YOfIntro ? (
                    <p>{obj2?.YOfIntro}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setYOfIntro(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Status of implementation of CBCS/elective course
                  </label>
                  {obj2?.Status ? (
                    <p>{obj2?.Status}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of implementation of CBCS/elective course
                  </label>
                  {obj2?.YOfCBCS ? (
                    <p>{obj2?.YOfCBCS}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setYOfCBCS(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of revision (if any)
                  </label>
                  {obj2?.YOfRevision ? (
                    <p>{obj2?.YOfRevision}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setYOfRevision(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  If revision has been carried out in the syllabus during the
                  last 5 years, Percentage of content added or replaced
                </label>
                {obj2?.Revision ? (
                  <p>{obj2?.Revision}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setRevision(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj2?.Desc2 ? (
                  <p>{obj2?.Desc2}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setDesc2(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="upload2"
                  id="upload2"
                  accept="image/*"
                  onChange={(e) => {
                    setLink2(e.target.files[0]);
                  }}
                />
              </div>

              <div className="text-end mt-4">
                {add2 ? (
                  <button
                    type="submit"
                    className="btn  btn-success m-1 "
                    onClick={() => {
                      cat2();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning m-1"
                      onClick={handleShow1}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <span>Criteria-01-121</span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Program Code
                      </label>

                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PrgmCode}
                            onChange={(e) => {
                              setPrgmCode(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PrgmCode}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Program Name
                      </label>

                      {edit2 ? (
                        <>
                          <select
                            class="form-select form-select-sm vi_0"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={PrgName1}
                            onChange={(e) => setPrgName1(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="BCA">BCA</option>
                            <option value="BSC">BSC</option>
                            <option value="BBA">BBA</option>
                            <option value="B.Com">B.Com</option>
                          </select>
                        </>
                      ) : (
                        <>{PrgName1}</>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year Of Introduction
                      </label>

                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={YOfIntro}
                            onChange={(e) => setYOfIntro(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{YOfIntro}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of revision (if any)
                      </label>

                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={YOfRevision}
                            onChange={(e) => setYOfRevision(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{YOfRevision}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Status of implementation of CBCS/elective course
                      </label>

                      {edit2 ? (
                        <>
                          <select
                            class="form-select form-select-sm vi_0"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={Status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </>
                      ) : (
                        <>{Status}</>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of implementation of CBCS/elective course
                      </label>

                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={YOfCBCS}
                            onChange={(e) => setYOfCBCS(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{YOfCBCS}</>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      If revision has been carried out in the syllabus during
                      the last 5 years, Percentage of content added or replaced
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Revision}
                          onChange={(e) => setRevision(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Revision}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Desc2}
                          onChange={(e) => setDesc2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Desc2}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload2"
                          id="name"
                          onChange={(e) => setLink2(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Link2?.name}</>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat2();
                      handleClose1();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 1.2.2 & 1.2.3</h3>
              <h6>Average enrolement percentage(Average of last five years)</h6>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of Add on /Certificate programs offered
                  </label>

                  {obj3?.CertiName ? (
                    <p>{obj3?.CertiName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setCertiName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code (if any)
                  </label>
                  {obj3?.CourseCode ? (
                    <p>{obj3?.CourseCode}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setCourseCode(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Year of offering</label>
                  {obj3?.YOfOffering ? (
                    <p>{obj3?.YOfOffering}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setYOfOffering(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    No. of times offered during the same year
                  </label>
                  {obj3?.NoOfTimes ? (
                    <p>{obj3?.NoOfTimes}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setNoOfTimes(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of course
                  </label>
                  {obj3?.CourseDuration ? (
                    <p>{obj3?.CourseDuration}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setCourseDuration(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students enrolled in the year
                  </label>
                  {obj3?.NoOfStudents ? (
                    <p>{obj3?.NoOfStudents}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setNoOfStudents(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students completing the course in the year
                  </label>
                  {obj3?.CourseCompletion ? (
                    <p>{obj3?.CourseCompletion}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setCourseCompletion(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj3?.Desc3 ? (
                    <p>{obj3?.Desc3}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc3(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Upload the relevant document
                  </label>

                  <input
                    className="vi_0"
                    type="file"
                    name="upload3"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setLink3(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="text-end mt-4">
                {add3 ? (
                  <button
                    type="submit"
                    className="btn  btn-success m-1"
                    onClick={() => {
                      cat3();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning m-1"
                      onClick={handleShow2}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <span>Criteria-01-122 & 123</span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of Add on /Certificate programs offered
                      </label>

                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={CertiName}
                            onChange={(e) => {
                              setCertiName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{CertiName}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Course Code (if any)
                      </label>

                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={CourseCode}
                            onChange={(e) => {
                              setCourseCode(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{CourseCode}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of offering
                      </label>

                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={YOfOffering}
                            onChange={(e) => {
                              setYOfOffering(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{YOfOffering}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        No. of times offered during the same year
                      </label>
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NoOfTimes}
                            onChange={(e) => {
                              setNoOfTimes(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NoOfTimes}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration of course
                      </label>

                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={CourseDuration}
                            onChange={(e) => {
                              setCourseDuration(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{CourseDuration}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of students enrolled in the year
                      </label>

                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NoOfStudents}
                            onChange={(e) => {
                              setNoOfStudents(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NoOfStudents}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of Students completing the course in the year
                      </label>

                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={CourseCompletion}
                            onChange={(e) => {
                              setCourseCompletion(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{CourseCompletion}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>

                      {edit3 ? (
                        <>
                          <textarea
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc3}
                            onChange={(e) => setDesc3(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc3}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>

                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload3"
                            id="upload3 "
                            onChange={(e) => setLink3(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link3?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success "
                    style={{ color: "white" }}
                    onClick={() => {
                      cat3();
                      handleClose2();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>
          <div className="container mt-3">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 1.3.2</h3>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  {obj4?.PrgName2 ? (
                    <p>{obj4?.PrgName2}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setPrgName2(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="BCA">BCA</option>
                      <option value="BSC">BSC</option>
                      <option value="BBA">BBA</option>
                      <option value="B.Com">B.Com</option>
                    </select>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>
                  {obj4?.PPrgmCode ? (
                    <p>{obj4?.PPrgmCode}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setPPrgmCode(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of offering
                  </label>
                  {obj4?.YYOfOffering ? (
                    <p>{obj4?.YYOfOffering}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setYYOfOffering(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </label>
                  {obj4?.StdCourseStudied ? (
                    <p>{obj4?.StdCourseStudied}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setStdCourseStudied(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </label>
                  {obj4?.ExpCourse ? (
                    <p>{obj4?.ExpCourse}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setExpCourse(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj4?.Desc4 ? (
                  <p>{obj4?.Desc4}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setDesc4(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload4">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  id="upload4"
                  accept="image/*"
                  onChange={(e) => {
                    setLink4(e.target.files[0]);
                  }}
                />
              </div>

              <div className="text-end mt-4">
                {add4 ? (
                  <button
                    type="submit"
                    className="btn btn-success m-1"
                    onClick={() => {
                      cat4();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning m-1"
                      onClick={handleShow3}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria01_132</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Program Name
                      </label>

                      {edit4 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={PrgName2}
                            onChange={(e) => {
                              setPrgName2(e.target.value);
                            }}
                          >
                            <option selected>--Select--</option>
                            <option value="BCA">BCA</option>
                            <option value="BSC">BSC</option>
                            <option value="BBA">BBA</option>
                            <option value="B.Com">B.Com</option>
                          </select>
                        </>
                      ) : (
                        <>{PrgName2}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Program Code
                      </label>

                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={PPrgmCode}
                            onChange={(e) => {
                              setPPrgmCode(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PPrgmCode}</>
                      )}
                    </div>

                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of offering
                      </label>

                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={YYOfOffering}
                            onChange={(e) => {
                              setYYOfOffering(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{YYOfOffering}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the student studied course on experiential
                        learning through project work/field work/internship
                      </label>

                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={StdCourseStudied}
                            onChange={(e) => {
                              setStdCourseStudied(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{StdCourseStudied}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the Course that include experiential learning
                        through project work/field work/internship
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={ExpCourse}
                            onChange={(e) => {
                              setExpCourse(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{ExpCourse}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <textarea
                            className="vi_0"
                            type="text"
                            name="email"
                            placeholder={Desc4}
                            onChange={(e) => setDesc4(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc4}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload4">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            id="upload4"
                            onChange={(e) => setLink4(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link4.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                  <Button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat4();
                      handleClose3();
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_1 (1.3.3)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  {obj5?.PrgName3 ? (
                    <p>{obj5?.PrgName3}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setPrgName3(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="BCA">BCA</option>
                      <option value="BSC">BSC</option>
                      <option value="BBA">BBA</option>
                      <option value="B.Com">B.Com</option>
                    </select>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>
                  {obj5?.PPPrgmCode ? (
                    <p>{obj5?.PPPrgmCode}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setPPPrgmCode(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    List of students undertaking project work/field
                    work/internship
                  </label>
                  {obj5?.StdList ? (
                    <p>{obj5?.StdList}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setStdList(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj5?.Desc5 ? (
                  <p>{obj5?.Desc5}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setDesc5(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="email"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setLink5(e.target.files[0]);
                  }}
                />
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add5 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat5();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow4}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                  <Modal.Title>Criteria01_133</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Program Name
                      </label>
                      {edit5 ? (
                        <>
                          <select
                            class="form-select form-select-sm vi_0"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={PrgName3}
                            onChange={(e) => {
                              setPrgName3(e.target.value);
                            }}
                          >
                            <option selected>--Select--</option>
                            <option value="BCA">BCA</option>
                            <option value="BSC">BSC</option>
                            <option value="BBA">BBA</option>
                            <option value="B.Com">B.Com</option>
                          </select>
                        </>
                      ) : (
                        <>{PrgName3}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        Program Code
                      </label>

                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={PPPrgmCode}
                            onChange={(e) => {
                              setPPPrgmCode(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PPPrgmCode}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        List of students undertaking project work/field
                        work/internship
                      </label>

                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={StdList}
                            onChange={(e) => {
                              setStdList(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{StdList}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        Description(min 500 words)
                      </label>

                      {edit5 ? (
                        <>
                          <textarea
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc5}
                            onChange={(e) => setDesc5(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc5}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        Upload the relevant document
                      </label>

                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload5"
                            accept="image/*"
                            onChange={(e) => {
                              setLink5(e.target.files[0]);
                            }}
                          />
                        </>
                      ) : (
                        <>{Link5?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit5(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat5();
                      handleClose4();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>
          <div className="container mt-3 mb-3">
            <div className="row-fluid">
              <div
                class=" col-md-6-fluid border bs"
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <Button
                  type="submit"
                  className="btn-success "
                  onClick={() => ugcriteria1()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UG_Criteria001;
